// eslint-disable-next-line no-redeclare,no-unused-vars
function buildSettingsView(param, xhr) {
  var json = xhr.result;
  if (!isPlainObject(json)) json = {};

  param.type = 'settings';
  param.fixSize = 4;
  if (param.objectSub == 'settingsClient') param.title = client + '.json';
  else if (param.objectSub == 'settingsIndustry')
    param.title = industry + 'Industry.json';
  else if (param.objectSub == 'settingsGlobal') param.title = 'global.json';

  var view = param.view;
  if (!view) {
    if (param.modal) view = buildPopup(param);
    else {
      param.bodyCard = true;
      param.scrollable = true;
      view = buildView(param);
    }
    view.body.addClass('settings');
  }

  // filter
  // var buttonBar = $('<div class="buttons1"></div>');
  // view.find('.ui-dialog-title').after(buttonBar).parent().addClass('extended');
  // $('<button class="button"><i class="fal fa-filter"></i> Filter</button>')
  // 	.appendTo(buttonBar)
  // 	.addTooltip('Filter')
  // 	.on(click, function () {
  // 		if (view.filterBox) {
  // 			view.filterBox.toggle();
  // 			return;
  // 		}
  // 		view.filterBox = $('<div class="filter box"></div>').insertBefore(view);
  // 		$('<div class="title"><span>Filter</span></div>').appendTo(view.filterBox);
  // 		line(view.filterBox, {
  // 			label: 'Suche',
  // 			save: function (value) {
  // 				view.find('*').addClass('hidden');
  // 				view.find('input[value*=' + escapeRegExp(value) + ']').each(openDeep);
  // 			}
  // 		});
  // 	});

  // menu
  var menu = {
    addElement: 'Neues Element',
    addNode: 'Neuer Knoten',
    array: 'Element als Array',
    remove: 'Element entfernen',
    jsonCopy: 'Json kopieren',
    jsonPaste: 'Json einbetten',
    token: 'Token erstellen',
    pushBranch: 'Verschieben in anderen Branch',
    pushClient: 'Kopieren in anderen Mandant',
    pushIndustry: 'Verschieben in Branche',
    pushGlobal: 'Verschieben in Global',
  };

  // go
  loop(view.body, json);
  var root = $('<div class="label" key="root">root</div>').prependTo(view.body);
  contextMenu(root, menu, menuClick);
  // view.afterReopen.push(function (param) {
  // 	if (param && param.open) openDeep(param);
  // });
  if (param.open) openDeep(param);

  function loop(block, settings, parent) {
    for (var key in settings) {
      var value = settings[key];
      var label, val;
      var id = key;
      if (parent) id = parent + '~' + id;

      if (isPlainObject(value)) {
        $('<div class="break image"></div>').appendTo(block).on(click, expand);
        label = $('<input key="' + id + '" class="label" value="' + key + '">')
          .appendTo(block)
          .on(click, expand);
        var sub = $('<div class="break levelblock"></div>').appendTo(block);
        loop(sub, value, id); // object value
      } else {
        var array = false;
        if (isArray(value)) {
          value = value.join(',');
          array = true;
        }
        label = $(
          '<input key="' + id + '" class="break label" value="' + key + '">'
        );
        var image = block.children('.image:first');
        if (image[0]) label.insertBefore(image);
        else label.appendTo(block);
        val = $('<input key="~' + id + '" class="value">')
          .val(value)
          .insertAfter(label);
        if (array) val.addClass('array');
      }
      if (!parent) {
        label.on('change', save);
        contextMenu(label, menu, menuClick);
        if (val) val.on('change', save);
      }
    }
  }

  function expand(evt) {
    var element = evt.currentTarget;
    var block;
    if ($(element).hasClass('image')) {
      block = $(element).next().next().toggle();
      $(element).toggleClass('open');
    } else {
      block = $(element).next().show();
      $(element).prev().addClass('open');
    }

    // add events
    if (!block.hasClass('eventsLoaded')) {
      block.children('.label,.value').on('change', save);
      block.addClass('eventsLoaded');
      contextMenu(block.children('.label'), menu, menuClick);
    }
  }

  function create(element, node, labelValue, valueValue) {
    if (!labelValue) labelValue = 'neu';
    var id = $(element).attr('key') + '~' + labelValue;
    var el = $('[key=' + $.escapeSelector(id) + ']');
    if (el[0]) {
      el.focus();
      return;
    }
    var root;

    // element to root
    if ($(element).attr('key') == 'root') {
      labelValue = prompt('Name:');
      if (!labelValue) return;
      id = labelValue;
      root = true;
    }

    // node
    else if (node) {
      var title = prompt('Name:');
      if (!title) return;
      const label = createElement('INPUT', {
        key: $(element).attr('key') + '~' + title,
        class: 'label',
        value: title,
      });
      $(element).next().prepend(label);
      $(label).on('change', save);
      contextMenu($(label), menu, menuClick);

      element = label;
      $(element).on(click, expand);
      $(element).before(
        createElement('DIV', {class: 'break image open', click: expand})
      );
      $(element).after(createElement('DIV', {class: 'break levelblock'}));
      id = $(element).attr('key') + '~neu';
    }

    // change to node
    else if (!$(element).next().hasClass('levelblock')) {
      //			if (!confirm('Wollen sie '+element.value+' wirklich zu ein Objekt verwandeln?')) return;
      $(element).removeClass('break');
      $(element).next().remove();
      $(element).on(click, expand);
      $(element).before(
        createElement('DIV', {class: 'break image open', click: expand})
      );
      $(element).after(createElement('DIV', {class: 'break levelblock'}));
    }

    // value
    var value = createElement('INPUT', {
      key: '~' + id,
      class: 'value',
      value: valueValue,
    });
    if (root) $(element).after(value);
    else $(element).next().prepend(value);
    $(value).on('change', save);

    // label
    const label = createElement('INPUT', {
      key: id,
      class: 'break label',
      value: labelValue,
    });
    if (root) $(element).after(label);
    else $(element).next().prepend(label);
    $(label).on('change', save);
    contextMenu($(label), menu, menuClick);

    // further actions
    $(label)[0].focus();
    $(label)[0].select();
  }

  function save(evt) {
    var element = evt.currentTarget;

    var value = element.value;
    if ($(element).hasClass('label'))
      $(element).next('.value').attr('disabled', true);
    if (!value && $(element).hasClass('label')) value = '';
    if ($(element).hasClass('array')) value = value.split(',');

    var send = {func: 'save'};
    send.id = $(element).attr('key');
    if ($(element).hasClass('label')) send.label = true;
    send.value = value;

    var pm = {
      object: param.object,
      objectSub: param.objectSub,
      post: 1,
      send: send,
    };
    // if (param.objectSub == 'settingsModul') pm.send.modul = param.send.modul;
    pm.callback = function () {
      if ($(element).hasClass('label')) {
        var keyOld = $(element).attr('key');
        var keyNew = keyOld.slice(0, keyOld.lastIndexOf('~') + 1) + value;
        $(element).attr('key', keyNew);

        // value change
        if ($(element).next().hasClass('value')) {
          $(element)
            .next()
            .removeAttr('disabled')
            .attr('key', '~' + keyNew);
        }

        // key change
        else {
          $(element)
            .next()
            .find('[key]')
            .each(function () {
              var key = $(this).attr('key').replace(keyOld, keyNew);
              $(this).attr('key', key);
            });
        }
      }
    };
    ajax(pm);
  }

  // TODO: unused, remove?
  // eslint-disable-next-line no-unused-vars
  function open(key, view2) {
    var keyName = '';
    $.each(key.split('~'), function (i, key) {
      keyName += (keyName ? '~' : '') + key;
      if (view2)
        view2.find('[key=' + $.escapeSelector(keyName) + ']').trigger(click);
      else view.find('[key=' + $.escapeSelector(keyName) + ']').trigger(click);
    });
    setTimeout(function () {
      if (view2) view2.find('[key=' + $.escapeSelector(key) + ']').focus();
      else view.find('[key=' + $.escapeSelector(key) + ']').focus();
    }, 100);
  }

  function openDeep(param) {
    var element = $(this);
    if (element.is('.value')) element = element.prev();
    if (param.open) {
      var key = $.escapeSelector(
        'objects~' + param.keyObject + '~' + param.key
      );
      element = view.find('.label[key=' + key + ']');
      if (!element[0]) {
        key = $.escapeSelector('objects~' + param.keyObject);
        element = view.find('.label[key=' + key + ']');
      }
    }

    element.parents('.levelblock').each(function () {
      $(this)
        .removeClass('hidden')
        .prev('.label')
        .removeClass('hidden')
        .prev('.image')
        .removeClass('hidden')
        .not('.open')
        .trigger(click);
    });
    element.removeClass('hidden');
    element.prev('.image').removeClass('hidden').not('.open').trigger(click);
    element.next().removeClass('hidden');
    element.next('.levelblock').find('.image:not(.open)').trigger(click);
    element.next('.levelblock').find('*').removeClass('hidden');
    setTimeout(function () {
      element.focus();
    }, 100);
  }

  function menuClick(type, target, label, evt) {
    if (type == 'addElement') {
      create(target);
    } else if (type == 'addNode') {
      create(target, true);
    } else if (type == 'array') {
      var element = target.next();
      if (element.hasClass('levelblock')) return;
      element.toggleClass('array').trigger('change');
      evt.preventDefault();
      evt.stopPropagation();
    } else if (type == 'remove') {
      target.prev('.image').remove();
      target.next('.levelblock').remove();
      target.next('.value').remove();
      target.remove();
      ajax({
        object: param.object,
        objectSub: param.objectSub,
        post: 1,
        view: view,
        send: {func: 'save', id: target.attr('key'), remove: true},
      });
    } else if (type == 'jsonCopy') {
      var keys = target.attr('key').split('~');
      var copy = Object.assign({}, json);
      $.each(keys, function (i, key) {
        copy = copy[key];
      });
      var copy2 = {};
      copy2[end(keys)] = copy;
      window.jsonTmp = JSON.stringify(copy2);
      showInfo(window.jsonTmp);
    } else if (type == 'jsonPaste') {
      var code = prompt('json', window.jsonTmp);
      if (!code) return;
      var key = target.attr('key');
      ajax({
        object: param.object,
        objectSub: param.objectSub,
        post: 1,
        view: view,
        send: {func: 'paste', id: key, code: code},
      });
      var add = JSON.parse(code);
      loop(target.next(), add, key);
    } else if (
      type == 'pushGlobal' ||
      type == 'pushIndustry' ||
      type == 'pushClient' ||
      type == 'pushBranch'
    ) {
      key = target.attr('key');
      var pm = {func: 'push', id: key};
      if (type == 'pushGlobal') pm.to = 'global';
      else if (type == 'pushIndustry') pm.to = 'industry';
      else if (type == 'pushClient') pm.to = 'client';
      else if (type == 'pushBranch') pm.to = 'branch';
      else return;
      if (type == 'pushClient' || type == 'pushBranch') {
        pm.name = prompt('name');
        if (!pm.name) return;
      }
      ajax({
        object: param.object,
        objectSub: param.objectSub,
        post: 1,
        view: view,
        send: pm,
        callback: function () {
          target.prev('.image').remove();
          target.next('.levelblock').remove();
          target.next('.value').remove();
          target.remove();
        },
      });
    } else if (type == 'token') {
      ajax({
        object: param.object,
        objectSub: param.objectSub,
        post: 1,
        view: view,
        send: {func: 'token'},
        callback: function (xhr) {
          target.next('.value').val(xhr.token).trigger('change');
        },
      });
    } else if (type == 'up' || type == 'down') {
      if (type == 'up') {
        var prev = target.prevAll('.label:first');
        if (!prev[0]) return;
        if (prev.prev().is('.image')) prev = prev.prev();
        var dot = target.prev('.image');
        target.next().addBack().insertBefore(prev);
        if (dot) dot.insertBefore(target);
      } else if (type == 'down') {
        var next = target.nextAll('.label:first');
        if (!next[0]) return;
        next = next.next();
        if (next.next().is('.levelblock')) next = next.next();
        dot = target.prev('.image');
        target.next().addBack().insertAfter(next);
        if (dot) dot.insertBefore(target);
      }
      var parent = target.parent().prev().attr('key');
      var keys = target
        .parent()
        .children('.label[key]')
        .map(function () {
          var key = $(this).attr('key');
          return key.slice(key.lastIndexOf('~') + 1);
        })
        .get();
      ajax({
        object: param.object,
        objectSub: param.objectSub,
        post: 1,
        send: {func: 'sort', id: parent, keys: keys},
      });
    }
  }

  function createElement(tag, param) {
    var element = document.createElement(tag);
    if (param)
      $.each(param, function (id, value) {
        if (id == 'html') $(element).html(value);
        else if (id == 'display' || id == 'width' || id == 'height')
          $(element).css(id, value);
        else if (id == 'click') $(element).on(click, value);
        // tooltip function most likely comes from Bootstrap, but I'm not entirely sure
        else if (id == 'tooltip') tooltip(element, value);
        else $(element).attr(id, value);
      });
    return element;
  }
}
